import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext
} from 'react';
import { checkService } from '../../api/nagios/checkService';
import { hostCount } from '../../api/nagios/hostCount';
import { HostCountResponse } from '../../utils/interfaces';

// Interface for GlobalStatusState
interface GlobalStatusState {
  hostingStatus: number;
  rocketslipStatus: number;
  xoneStatus: number;
  setHostingStatus: (status: number) => void;
  setRocketslipStatus: (status: number) => void;
  setXoneStatus: (status: number) => void;
}

// Initial state for GlobalStatusContext
const initialGlobalState: GlobalStatusState = {
  hostingStatus: 0,
  rocketslipStatus: 0,
  xoneStatus: 0,
  setHostingStatus: () => {},
  setRocketslipStatus: () => {},
  setXoneStatus: () => {}
};

// Create the Global Status context
const GlobalStatusContext =
  createContext<GlobalStatusState>(initialGlobalState);

// Props interface for providers
interface ProviderProps {
  children: ReactNode;
}

export const GlobalStatusProvider: React.FC<ProviderProps> = ({ children }) => {
  const [hostingStatus, setHostingStatus] = useState<number>(0);
  const [rocketslipStatus, setRocketslipStatus] = useState<number>(0);
  const [xoneStatus, setXoneStatus] = useState<number>(0);

  const value = {
    hostingStatus,
    rocketslipStatus,
    xoneStatus,
    setHostingStatus,
    setRocketslipStatus,
    setXoneStatus
  };

  useEffect(() => {
    const checkGlobalHostingStatus = () => {
      console.log('globalhostingStatus:', hostingStatus);
    };

    checkGlobalHostingStatus();

    const interval = setInterval(checkGlobalHostingStatus, 60000); // Run updateStatus every minute (60000 milliseconds)

    console.log('hostingStatus Global Set', hostingStatus);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [hostingStatus, rocketslipStatus, xoneStatus]);

  return (
    <GlobalStatusContext.Provider value={value}>
      {children}
    </GlobalStatusContext.Provider>
  );
};

export default GlobalStatusContext;
