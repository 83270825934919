import '../../../../assets/css/styles.css';
import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { StatusCard, getAPIStatus } from '../components';
import XoneStatusContext from '../../../contextProviders/contextProviderXone';

interface ApiStatusProps {
  onStatusUpdate: (status: number) => void;
}
export const ApiStatus: React.FC<ApiStatusProps> = ({ onStatusUpdate }) => {
  const { apiStatus } = useContext(XoneStatusContext);

  useEffect(() => {
    onStatusUpdate(apiStatus);
  }, [apiStatus, onStatusUpdate]);

  const serviceStatusConfig = getAPIStatus(apiStatus);

  return (
    <div className="col-lg-12">
      <div className="row"></div>
      <div className="row">
        <div className="my-4 col-lg-12">
          <motion.div
            initial={{ opacity: 0, z: -1000, rotateX: -90 }}
            animate={{ opacity: 1, z: 0, rotateX: 0 }}
            transition={{ duration: 1 }}
          >
            <StatusCard
              title={serviceStatusConfig.title}
              text={serviceStatusConfig.text}
              className={serviceStatusConfig.className}
              status={apiStatus}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
