import React, { useEffect, useState } from 'react';
import { NetworkStatus } from './categories/networkStatus';
import { WebServerStatus } from './categories/webStatus';
import { MaintenanceStatus } from './categories/upcomingMaintenance';

interface HostingStatusProps {
  onStatusUpdate?: (status: 0 | 1 | 2 | 3) => void;
}

export const HostingStatus: React.FC<HostingStatusProps> = ({
  onStatusUpdate
}) => {
  const [maintenanceServiceStatus, setMaintenanceServiceStatus] =
    useState<number>(0);

  const [webserverServiceStatus, setWebserverServiceStatus] =
    useState<number>(0);

  const [networkServiceStatus, setNetworkServiceStatus] = useState<number>(0);

  const updateStatus = () => {
    const highestStatus = Math.max(
      maintenanceServiceStatus,
      webserverServiceStatus,
      networkServiceStatus
    );

    let newStatus: 0 | 1 | 2 | 3;
    if (highestStatus === 1) {
      newStatus = 1;
    } else if (highestStatus === 2) {
      newStatus = 2;
    } else if (highestStatus === 3) {
      newStatus = 3;
    } else {
      newStatus = 0;
    }

    if (onStatusUpdate) onStatusUpdate(newStatus);
  };

  useEffect(() => {
    console.log(
      'maintenanceServiceStatus, webserverServiceStatus, networkServiceStatus',
      maintenanceServiceStatus,
      webserverServiceStatus,
      networkServiceStatus
    );
    updateStatus();
  }, [maintenanceServiceStatus, webserverServiceStatus, networkServiceStatus]);

  return (
    <div className="container-fluid fullWidthHome">
      <section id="content">
        <div className="container-fluid my-3">
          <div className="row">
            <MaintenanceStatus onStatusUpdate={setMaintenanceServiceStatus} />
            <WebServerStatus onStatusUpdate={setWebserverServiceStatus} />
            <NetworkStatus onStatusUpdate={setNetworkServiceStatus} />
          </div>
        </div>
      </section>
    </div>
  );
};
