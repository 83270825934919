import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext
} from 'react';
import { checkService } from '../../api/nagios/checkService';
import GlobalStatusContext from './contextProvider';

// Interface for XoneStatusState
interface XonetatusState {
  maintenanceStatus: number;
  websiteStatus: number;
  apiStatus: number;
  setMaintenanceStatus: (status: number) => void;
  setWebsiteStatus: (status: number) => void;
  setApiStatus: (status: number) => void;
}

// Initial state for XoneStatusContext
const initialXoneState: XonetatusState = {
  maintenanceStatus: 0,
  apiStatus: 0,
  websiteStatus: 0,
  setMaintenanceStatus: () => {},
  setWebsiteStatus: () => {},
  setApiStatus: () => {}
};

// Create the Rockeslip Status context
export const XoneStatusContext =
  createContext<XonetatusState>(initialXoneState);

// Props interface for providers
interface ProviderProps {
  children: ReactNode;
}

// XoneStatusProvider component
export const XoneStatusProvider: React.FC<ProviderProps> = ({ children }) => {
  const { setXoneStatus } = useContext(GlobalStatusContext);
  const [maintenanceStatus, setMaintenanceStatus] = useState<number>(0);
  const [websiteStatus, setWebsiteStatus] = useState<number>(0);
  const [apiStatus, setApiStatus] = useState<number>(0);

  const value = {
    maintenanceStatus,
    websiteStatus,
    apiStatus,
    setMaintenanceStatus,
    setWebsiteStatus,
    setApiStatus
  };

  // Maintenance Status
  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const maintenanceStatus = 0;
        setMaintenanceStatus(maintenanceStatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMaintenanceData();

    const interval = setInterval(fetchMaintenanceData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus]);

  // Set Website Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkService(
          'x-one.cloud.xfour.co.za',
          'Website+Check+-+X-One'
        );
        console.log('x-one website - response', response);
        setWebsiteStatus(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [websiteStatus]);

  // Set API Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkService(
          'x-one.cloud.xfour.co.za',
          'X-One+API+Port+Check'
        );
        console.log('X-One API - Response', response);
        setApiStatus(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [websiteStatus]);

  // Set Global Xone Status
  useEffect(() => {
    const updateGlobalXoneStatus = () => {
      const highestStatus = Math.max(
        maintenanceStatus,
        websiteStatus,
        apiStatus
      );

      console.log('maintenanceStatus', maintenanceStatus);
      console.log('websiteStatus', websiteStatus);
      console.log('apiStatus', apiStatus);

      let newStatus: 0 | 1 | 2 | 3;
      if (highestStatus === 1) {
        newStatus = 1;
      } else if (highestStatus === 2) {
        newStatus = 2;
      } else if (highestStatus === 3) {
        newStatus = 3;
      } else {
        newStatus = 0;
      }

      setXoneStatus(newStatus);
    };

    updateGlobalXoneStatus();

    const interval = setInterval(updateGlobalXoneStatus, 60000); // Run updateStatus every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus, websiteStatus, apiStatus, setXoneStatus]);

  return (
    <XoneStatusContext.Provider value={value}>
      {children}
    </XoneStatusContext.Provider>
  );
};

export default XoneStatusContext;
