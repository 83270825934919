import axios from 'axios';
import { authEncode, decryptData, encryptData } from '../helpers';

interface ApiResponse {
  up: number;
  down: number;
  unreachable: number;
  pending: number;
}

export const hostCount = async (
  dateStamp: string,
  hostGroup: string
): Promise<ApiResponse> => {
  const data = JSON.stringify({
    dateStamp: `${dateStamp}`,
    hostGroup: `${hostGroup}`
  });

  const { REACT_APP_API_URL: apiUrl } = process.env;

  // Basic auth
  const base64Credentials = authEncode();
  const encryptedData = encryptData(data);

  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `${apiUrl}/host-count`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${base64Credentials}`
    },
    data: { props: encryptedData }
  };

  try {
    const response = await axios(config);
    const decryptedResponse = decryptData(response.data);

    return decryptedResponse as ApiResponse;
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      console.error(error.message);
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
