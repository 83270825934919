import '../../../../assets/css/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { HostingCard, getNetworkStatusConfig } from '../components';
import HostingStatusContext from '../../../contextProviders/contextProviderHosting';

interface NetworkStatusProps {
  onStatusUpdate: (status: number) => void;
}

export const NetworkStatus: React.FC<NetworkStatusProps> = ({
  onStatusUpdate
}) => {
  const { networkStatus } = useContext(HostingStatusContext);

  const [serviceStatus, setServiceStatus] = useState<number>(0);

  useEffect(() => {
    setServiceStatus(networkStatus); // Update the local state based on the context
    console.log('networkStatus!!', networkStatus);
  }, [networkStatus]);

  useEffect(() => {
    console.log(`Status[Network]:${serviceStatus}`);
    onStatusUpdate(serviceStatus);
  }, [serviceStatus, onStatusUpdate]);

  // Ensure that the config is retrieved with the current serviceStatus
  const networkStatusConfig = getNetworkStatusConfig(serviceStatus);

  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="my-4 col-lg-12">
          <motion.div
            initial={{ opacity: 0, z: -1000, rotateX: -90 }}
            animate={{ opacity: 1, z: 0, rotateX: 0 }}
            transition={{ duration: 1 }}
            style={{ cursor: 'pointer' }}
          >
            <HostingCard
              title={networkStatusConfig.title}
              text={networkStatusConfig.text}
              className={networkStatusConfig.className}
              status={serviceStatus}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
