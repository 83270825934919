import { WebsiteStatusConfig } from '../../../../utils/interfaces';

export const websiteMessages: WebsiteStatusConfig[] = [
  {
    title: 'Website Status',
    text: `
      <b>There are no problems to report at this moment.</b></br>
      All services are functioning optimally with no reported issues.
    `,
    className: 'nagios-success-status text-dark',
    status: 0
  },
  {
    title: 'Website Status',
    text: `
      <b>Title:</b> Warning reported on Website status<br>
      <b>Current Status:</b> The issue is currently being investigated, and corrective measures are being taken to resolve it promptly.<br>
      <ul>
        <li>Users connecting to the website may experience intermittent connectivity issues.</li>
      </ul>
    `,
    className: 'bg-warning',
    status: 1
  },
  {
    title: 'Website Status',
    text: `
      <b>Title:</b> Critical status reported on Website<br>
      <b>Current Status:</b> Investigation and remediation efforts are underway. Our network engineering team is actively working to diagnose and resolve the issue.<br>
      <b>Impact:</b><br>
      <ul>
        <li>Users will not be able to access our website.</li>
      </ul>
    `,
    className: 'bg-danger text-white',
    status: 2
  },
  {
    title: 'Website Status',
    text: 'Unable to determine website status.',
    status: 3
  }
];
