import '../../../../assets/css/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { checkService } from '../../../../api/nagios/checkService';
import { HostingCard, getWebServerStatus } from '../components';
import HostingStatusContext from '../../../contextProviders/contextProviderHosting';

interface WebServerStatusProps {
  onStatusUpdate: (status: number) => void;
}

export const WebServerStatus: React.FC<WebServerStatusProps> = ({
  onStatusUpdate
}) => {
  const { webserverStatus } = useContext(HostingStatusContext);

  const [serviceStatus, setServiceStatus] = useState<number>(0);

  useEffect(() => {
    setServiceStatus(webserverStatus);
  }, [webserverStatus]);

  useEffect(() => {
    onStatusUpdate(serviceStatus);
  }, [serviceStatus, onStatusUpdate]);

  const serviceStatusConfig = getWebServerStatus(serviceStatus);

  return (
    <div className="col-lg-12">
      <div className="row"></div>
      <div className="row">
        <div className="my-4 col-lg-12">
          <motion.div
            initial={{ opacity: 0, z: -1000, rotateX: -90 }}
            animate={{ opacity: 1, z: 0, rotateX: 0 }}
            transition={{ duration: 1 }}
          >
            <HostingCard
              title={serviceStatusConfig.title}
              text={serviceStatusConfig.text}
              className={serviceStatusConfig.className}
              status={serviceStatus}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
