import { WebsiteStatusConfig } from '../../../../utils/interfaces';

export const apiMessages: WebsiteStatusConfig[] = [
  {
    title: 'API Status',
    text: `
                <b>There are no problems to report at this moment.</b><br>
                All services are functioning optimally with no reported issues.
            `,
    className: 'nagios-success-status text-dark',
    status: 0
  },
  {
    title: 'API Status',
    text: `
                <b>Title:</b> Some API Services Affected<br>
                <b>Current Status:</b> The issue is currently being investigated, and corrective measures are underway to resolve it promptly.<br>
                <ul>
                    <li>API tests indicate degraded performance, and users may experience intermittent connectivity.</li>
                </ul>
            `,
    className: 'bg-warning',
    status: 1
  },
  {
    title: 'API Status',
    text: `
                <b>Title:</b> API Service Unavailable<br>
                <b>Current Status:</b> Investigation and remediation efforts are ongoing. Our network engineering team is actively working to diagnose and resolve the issue.<br>
                <b>Impact:</b> API layer is affected.<br>
                <ul>
                    <li>API Integration services are currently unavailable.</li>
                </ul>
            `,
    className: 'bg-danger text-white',
    status: 2
  },
  {
    title: 'Status Unknown',
    text: 'Unable to determine the API status.',
    status: 3
  }
];
