import '../../../../assets/css/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { StatusCard, getUpcomingMaintenance } from '../components';
import XoneStatusContext from '../../../contextProviders/contextProviderXone';

interface MaintenanceStatusProps {
  onStatusUpdate: (status: number) => void;
}

interface ServiceStatusConfig {
  title: string;
  text: string;
  className: string;
}

export const MaintenanceStatus: React.FC<MaintenanceStatusProps> = ({
  onStatusUpdate
}) => {
  const { maintenanceStatus } = useContext(XoneStatusContext);
  // Define a default config
  const defaultConfig: ServiceStatusConfig = {
    title: 'Unknown Status',
    text: 'Status details are unavailable at this time.',
    className: 'unknown-status'
  };

  const [serviceStatusConfig, setServiceStatusConfig] =
    useState<ServiceStatusConfig>(defaultConfig);

  useEffect(() => {
    const config = getUpcomingMaintenance(maintenanceStatus);
    setServiceStatusConfig({
      title: config?.title || defaultConfig.title,
      text: config?.text || defaultConfig.text,
      className: config?.className || defaultConfig.className
    });

    onStatusUpdate(maintenanceStatus);
  }, [maintenanceStatus, onStatusUpdate]);

  // Ensure that getUpcomingMaintenance returns a valid object

  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="my-4 col-lg-12">
          <motion.div
            initial={{ opacity: 0, z: -1000, rotateX: -90 }}
            animate={{ opacity: 1, z: 0, rotateX: 0 }}
            transition={{ duration: 1 }}
          >
            <StatusCard
              title={serviceStatusConfig.title}
              text={serviceStatusConfig.text}
              className={serviceStatusConfig.className}
              status={maintenanceStatus}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
