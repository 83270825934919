import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext
} from 'react';
import { checkService } from '../../api/nagios/checkService';
import { hostCount } from '../../api/nagios/hostCount';
import { HostCountResponse } from '../../utils/interfaces';
import GlobalStatusContext from './contextProvider';

// Interface for HostingStatusState
interface HostingStatusState {
  maintenanceStatus: number;
  networkStatus: number;
  webserverStatus: number;
  setMaintenanceStatus: (status: number) => void;
  setNetworkStatus: (status: number) => void;
  setWebserverStatus: (status: number) => void;
}

// Initial state for HostingStatusContext
const initialHostingState: HostingStatusState = {
  maintenanceStatus: 0,
  networkStatus: 0,
  webserverStatus: 0,
  setMaintenanceStatus: () => {},
  setNetworkStatus: () => {},
  setWebserverStatus: () => {}
};

// Create the Hosting Status context
export const HostingStatusContext =
  createContext<HostingStatusState>(initialHostingState);

// Props interface for providers
interface ProviderProps {
  children: ReactNode;
}

// HostingStatusProvider component
export const HostingStatusProvider: React.FC<ProviderProps> = ({
  children
}) => {
  const { setHostingStatus } = useContext(GlobalStatusContext);
  const [networkStatus, setNetworkStatus] = useState<number>(0);
  const [webserverStatus, setWebserverStatus] = useState<number>(0);
  const [maintenanceStatus, setMaintenanceStatus] = useState<number>(0);
  const [hostCounts, setHostCounts] = useState<HostCountResponse>({
    down: 0,
    up: 0
  });

  const value = {
    networkStatus,
    webserverStatus,
    maintenanceStatus,
    setNetworkStatus,
    setMaintenanceStatus,
    setWebserverStatus
  };

  // Maintenance Status
  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const maintenanceStatus = 0;
        setMaintenanceStatus(maintenanceStatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMaintenanceData();

    const interval = setInterval(fetchMaintenanceData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus]);

  // Set Webserver Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkService(
          'web-x4-001-za.xfourtech.co.za',
          'apache2'
        );
        console.log(`Hosting Context Status[WebServer]:${response}`);
        setWebserverStatus(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    const fetchHostCount = async () => {
      try {
        const responseHostCount = await hostCount('today', 'Hyper-V-Hosts');
        setHostCounts(responseHostCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchHostCount();

    const interval = setInterval(fetchHostCount, 60000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  // Set Network Status
  useEffect(() => {
    try {
      if (
        typeof hostCounts !== 'object' ||
        hostCounts === null ||
        !('down' in hostCounts) ||
        !('up' in hostCounts)
      ) {
        setNetworkStatus(3);
      }

      const { down, up } = hostCounts;

      if (typeof down !== 'number' || typeof up !== 'number') {
        setNetworkStatus(3);
      }

      if (down === 0) {
        setNetworkStatus(0);
      } else if (down > 0 && down < up) {
        setNetworkStatus(1);
      } else if (down > up) {
        setNetworkStatus(2);
      } else {
        setNetworkStatus(3);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [hostCounts]);

  // Set Global Hosting Status
  useEffect(() => {
    const updateGlobalHostingStatus = () => {
      const highestStatus = Math.max(
        maintenanceStatus,
        networkStatus,
        webserverStatus
      );

      let newStatus: 0 | 1 | 2 | 3;
      if (highestStatus === 1) {
        newStatus = 1;
      } else if (highestStatus === 2) {
        newStatus = 2;
      } else if (highestStatus === 3) {
        newStatus = 3;
      } else {
        newStatus = 0;
      }

      setHostingStatus(newStatus);
    };

    updateGlobalHostingStatus();

    const interval = setInterval(updateGlobalHostingStatus, 60000); // Run updateStatus every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus, networkStatus, webserverStatus, setHostingStatus]);

  return (
    <HostingStatusContext.Provider value={value}>
      {children}
    </HostingStatusContext.Provider>
  );
};

export default HostingStatusContext;
