import { useContext, useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import '../../assets';
import '../../assets/css/styles.css';
import { HostingStatus, RocketSlipStatus, XoneStatus } from '../monitors';
import { Header } from '../header';
// import React from 'react';
import GlobalStatusContext from '../contextProviders/contextProvider';

export const HomePage = () => {
  const globalStatus = useContext(GlobalStatusContext);
  console.log('globalStatus', globalStatus);
  const { setXoneStatus } = globalStatus;

  const [selectedCategory, setSelectedCategory] = useState<string>('Hosting');

  interface StatusCategory {
    text: string;
    component: JSX.Element;
  }

  const statusCategories: StatusCategory[] = [
    {
      text: 'Hosting',
      component: <HostingStatus />
    },
    {
      text: 'Rocketslip',
      component: <RocketSlipStatus />
    },
    {
      text: 'X-One',
      component: <XoneStatus onStatusUpdate={setXoneStatus} />
    }
  ];

  const handleCategorySelection = (categoryText: string) => {
    setSelectedCategory(categoryText);
  };

  const renderSelectedComponent = () => {
    const category = statusCategories.find(
      (cat) => cat.text === selectedCategory
    );
    return category ? category.component : null;
  };

  return (
    <>
      <Header
        onSelectCategory={handleCategorySelection}
        selectedCategory={selectedCategory}
        globalStatus={globalStatus}
      />
      {renderSelectedComponent()}
    </>
  );
};
