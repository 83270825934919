import { WebsiteStatusConfig } from '../../../../utils/interfaces';

export const maintenanceMessages: WebsiteStatusConfig[] = [
  {
    title: `Upcoming Maintenance`,
    text: `
      <b>There are currently no planned maintenance scheduled.</b></br>
      We will keep you informed about any upcoming maintenance events.
    `,
    className: 'nagios-success-status text-dark',
    status: 0
  },
  {
    title: 'Upcoming Maintenance',
    text: `
      <b>Title:</b> Planned Maintenance scheduled for API services upgrade.<br>
      <b>Start:</b> 05 Apr 2024 06:00 SAST<br>
      <ul>
        <li>Updates on operating system.</li>
      </ul>
    `,
    className: 'bg-warning',
    status: 1
  }
];
