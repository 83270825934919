import CryptoJS from 'crypto-js';

/**
 * Encodes credentials using base64 encoding. This function can be used to generate
 * an authorization header for HTTP Basic Authentication.
 *
 * @returns {string} The base64 encoded credentials in the format "username:password".
 * @throws {Error} Throws an error on parameter checks
 * @example
 */
export const authEncode = (): string => {
  const usr: string | undefined = process.env.REACT_APP_API_USR;
  const pwd: string | undefined = process.env.REACT_APP_API_PWD;

  // Ensure that the environment variables are defined
  if (!usr || !pwd) {
    throw new Error('Forbidden');
  }

  const res = btoa(`${usr}:${pwd}`);
  return res;
};

export const encryptData = (data: any) => {
  const key = process.env.REACT_APP_API_ENC_KEY;

  if (!key) {
    throw new Error('Unauthorized');
  }

  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

export const decryptData = (encryptedData: string) => {
  const key: string | undefined = process.env.REACT_APP_API_ENC_KEY;

  if (!key) {
    throw new Error('Unauthorized');
  }

  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedData);
};
