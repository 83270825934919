import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import Zoom from '@mui/material/Zoom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export const StatusDialog: React.FC<{
  isOpen: boolean;
  close: () => void;
  dialogContent: { Title: string; Body: string };
}> = ({ isOpen, close, dialogContent }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{dialogContent.Title || ''}</DialogTitle>

      <DialogContent>
        <div
          id="alert-dialog-slide-description"
          dangerouslySetInnerHTML={{
            __html: dialogContent.Body || 'Default content here.'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
