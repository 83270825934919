import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import { StatusDialog } from './dialogs';
import { useCallback, useState } from 'react';

export const WebsiteCard: React.FC<{
  title: string;
  text: string;
  status: number;
  className?: string;
}> = ({ title, text, status }) => {
  const [showDialog, setDialogVisibility] = useState(false);

  // Dialog actions
  const handleDialogOpen = useCallback(() => {
    setDialogVisibility(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogVisibility(false);
  }, []);

  return (
    <div className={`card has-box-shadow card-body-pos`}>
      <div className={`card-body`}>
        <h5 className="card-title">{title}</h5>
        <div className={`indicator`}>
          {status === 0 && (
            <CheckCircleIcon
              className={`text-success`}
              sx={{ color: '', width: '25px', height: '25px' }}
            />
          )}
          {status === 1 && (
            <ErrorIcon
              className="text-warning"
              sx={{ width: '25px', height: '25px' }}
            />
          )}
          {status === 2 && (
            <CancelIcon sx={{ color: 'red', width: '25px', height: '25px' }} />
          )}
          {status === 3 && <HelpIcon sx={{ width: '25px', height: '25px' }} />}
        </div>

        <p className="card-text" dangerouslySetInnerHTML={{ __html: text }}></p>
        {/* <div style={{ textAlign: 'right' }}>
          <button className="btn btn-primary" onClick={handleDialogOpen}>
            Read More
          </button>
        </div> */}
      </div>
      <StatusDialog
        isOpen={showDialog}
        close={handleDialogClose}
        dialogContent={{ Title: title, Body: text }}
      />
    </div>
  );
};
