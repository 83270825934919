import React, { useEffect, useState } from 'react';
import { WebsiteStatus } from './categories/websiteStatus';
import { ApiStatus } from './categories/apiStatus';
import { MaintenanceStatus } from './categories/upcomingMaintenance';

interface XoneStatusProps {
  onStatusUpdate?: (status: 0 | 1 | 2 | 3) => void;
}

export const XoneStatus: React.FC<XoneStatusProps> = ({ onStatusUpdate }) => {
  const [maintenanceServiceStatus, setMaintenanceServiceStatus] =
    useState<number>(0);

  const [websiteServiceStatus, setWebSiteServiceStatus] = useState<number>(0);

  const [apiServiceStatus, setApiServiceStatus] = useState<number>(0);

  const updateStatus = () => {
    const highestStatus = Math.max(
      maintenanceServiceStatus,
      websiteServiceStatus,
      apiServiceStatus
    );

    let newStatus: 0 | 1 | 2 | 3;
    if (highestStatus === 1) {
      newStatus = 1;
    } else if (highestStatus === 2) {
      newStatus = 2;
    } else if (highestStatus === 3) {
      newStatus = 3;
    } else {
      newStatus = 0;
    }

    if (onStatusUpdate) onStatusUpdate(newStatus);
  };

  useEffect(() => {
    updateStatus();
  }, [maintenanceServiceStatus, websiteServiceStatus, apiServiceStatus]);

  return (
    <div className="container-fluid fullWidthHome">
      <section id="content">
        <div className="container-fluid my-3">
          <div className="row">
            <MaintenanceStatus onStatusUpdate={setMaintenanceServiceStatus} />
          </div>
          <div className="row">
            <WebsiteStatus onStatusUpdate={setWebSiteServiceStatus} />
          </div>
          <div className="row">
            <ApiStatus onStatusUpdate={setApiServiceStatus} />
          </div>
        </div>
      </section>
    </div>
  );
};
