import axios from 'axios';
import { authEncode, decryptData, encryptData } from '../helpers';

export const checkService = async (
  hostName: string,
  serviceName: string
): Promise<number> => {
  const data = JSON.stringify({
    hostName: `${hostName}`,
    serviceName: `${serviceName}`
  });

  const { REACT_APP_API_URL: apiUrl } = process.env;

  // Basic auth
  const base64Credentials = authEncode();
  const encryptedData = encryptData(data);

  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `${apiUrl}/service-check`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${base64Credentials}`
    },
    data: { props: encryptedData }
  };

  try {
    const response = await axios(config);
    const decryptedResponse = decryptData(response.data);

    return decryptedResponse as number;
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      console.error(error.message);
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
