import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext
} from 'react';
import { checkService } from '../../api/nagios/checkService';
import GlobalStatusContext from './contextProvider';

// Interface for RocketslipStatusState
interface RocketsliptatusState {
  maintenanceStatus: number;
  websiteStatus: number;
  apiStatus: number;
  setMaintenanceStatus: (status: number) => void;
  setWebsiteStatus: (status: number) => void;
  setApiStatus: (status: number) => void;
}

// Initial state for RocketslipStatusContext
const initialRocketslipState: RocketsliptatusState = {
  maintenanceStatus: 0,
  apiStatus: 0,
  websiteStatus: 0,
  setMaintenanceStatus: () => {},
  setWebsiteStatus: () => {},
  setApiStatus: () => {}
};

// Create the Rockeslip Status context
export const RocketslipStatusContext = createContext<RocketsliptatusState>(
  initialRocketslipState
);

// Props interface for providers
interface ProviderProps {
  children: ReactNode;
}

// RocketSlipStatusProvider component
export const RocketSlipStatusProvider: React.FC<ProviderProps> = ({
  children
}) => {
  const { setRocketslipStatus } = useContext(GlobalStatusContext);
  const [maintenanceStatus, setMaintenanceStatus] = useState<number>(0);
  const [websiteStatus, setWebsiteStatus] = useState<number>(0);
  const [apiStatus, setApiStatus] = useState<number>(0);

  const value = {
    maintenanceStatus,
    websiteStatus,
    apiStatus,
    setMaintenanceStatus,
    setWebsiteStatus,
    setApiStatus
  };

  // Maintenance Status
  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const maintenanceStatus = 0;
        setMaintenanceStatus(maintenanceStatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMaintenanceData();

    const interval = setInterval(fetchMaintenanceData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus]);

  // Set Website Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkService(
          'web-x4-001-za.xfourtech.co.za',
          'Website+Check+-+RocketSlip'
        );
        console.log('rocketslip website - response', response);
        setWebsiteStatus(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [websiteStatus]);

  // Set API Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkService(
          'wa.meta.systems.xfour.co.za',
          'Rocketslip+Production+Service+Status'
        );

        console.log(`Status[RS Api]:${response}`);
        setApiStatus(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Run fetchData every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [websiteStatus]);

  // Set Global Rocketslip Status
  useEffect(() => {
    const updateGlobalRocketslipStatus = () => {
      const highestStatus = Math.max(
        maintenanceStatus,
        websiteStatus,
        apiStatus
      );

      console.log('maintenanceStatus', maintenanceStatus);
      console.log('websiteStatus', websiteStatus);
      console.log('apiStatus', apiStatus);

      let newStatus: 0 | 1 | 2 | 3;
      if (highestStatus === 1) {
        newStatus = 1;
      } else if (highestStatus === 2) {
        newStatus = 2;
      } else if (highestStatus === 3) {
        newStatus = 3;
      } else {
        newStatus = 0;
      }

      console.log('newStatus------------>', newStatus);

      setRocketslipStatus(newStatus);
    };

    updateGlobalRocketslipStatus();

    const interval = setInterval(updateGlobalRocketslipStatus, 60000); // Run updateStatus every minute (60000 milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [maintenanceStatus, websiteStatus, apiStatus, setRocketslipStatus]);

  return (
    <RocketslipStatusContext.Provider value={value}>
      {children}
    </RocketslipStatusContext.Provider>
  );
};

export default RocketslipStatusContext;
