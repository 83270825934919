import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
// import '../../assets/css/styles.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';

export const Header: React.FC<{
  onSelectCategory: (category: string) => void;
  selectedCategory: string;
  globalStatus: any;
}> = ({ onSelectCategory, selectedCategory, globalStatus }) => {
  const statusConfig: Record<number, IconConfig> = {
    0: {
      IconComponent: CheckCircleIcon,
      tooltip: 'Everything is looking good!',
      sx: { width: '20px', height: '20px', padding: '0px' },
      className: 'text-success'
    },
    1: {
      IconComponent: ErrorIcon,
      tooltip: 'Some items detected!',
      sx: { width: '20px', height: '20px', padding: '0px' },
      className: 'text-warning'
    },
    2: {
      IconComponent: CancelIcon,
      tooltip: 'Critical items detected!',
      sx: { color: 'red', width: '20px', height: '20px', padding: '0px' }
    },
    3: {
      IconComponent: HelpIcon,
      tooltip: 'Need help?',
      sx: { width: '20px', height: '20px', padding: '0px' }
    }
  };

  const getNavigationIcon = (status: 0 | 1 | 2 | 3) => {
    const { IconComponent, tooltip, sx, className } =
      statusConfig[status] || statusConfig[3]; // Default to case 3 if status is not found

    return (
      <Tooltip title={tooltip}>
        <IconComponent sx={sx} className={className} />
      </Tooltip>
    );
  };

  const [hostingIcon, setHostingIcon] = useState<React.ReactNode>(() =>
    getNavigationIcon(globalStatus.hostingStatus)
  );
  const [rocketslipIcon, setRocketslipIcon] = useState<React.ReactNode>(() =>
    getNavigationIcon(globalStatus.rocketslipStatus)
  );
  const [xoneIcon, setXoneIcon] = useState<React.ReactNode>(() =>
    getNavigationIcon(globalStatus.xoneStatus)
  );

  useEffect(() => {
    setHostingIcon(getNavigationIcon(globalStatus.hostingStatus));
    setRocketslipIcon(getNavigationIcon(globalStatus.rocketslipStatus));
    setXoneIcon(getNavigationIcon(globalStatus.xoneStatus));
  }, [globalStatus]);

  interface IconConfig {
    IconComponent: React.ElementType;
    tooltip: string;
    sx?: object;
    className?: string;
  }

  interface globalMessagesItem {
    status: 0 | 1 | 2 | 3;
    messageHeader: string;
    messageBody: string;
  }

  interface globalMessagesItems extends Array<globalMessagesItem> {}

  const globalMessagesHeader: globalMessagesItems = [
    {
      status: 0,
      messageHeader: 'Automation, Integration and Simplification',
      messageBody: 'We care about your business.'
    }
  ];

  const [displayHeaderMessage] = useState<globalMessagesItem>(
    globalMessagesHeader[0]
  );

  return (
    <header>
      <div style={{ textAlign: 'center' }} className="fullWidthHome bg-primary">
        <div className="container container-m topContainerH">
          <div className="row ">
            <div className="bannerText">
              <motion.h3
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="fw-bold">Network Notices</span>
              </motion.h3>

              <motion.h5
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="fw-bold text-white">
                  {displayHeaderMessage.messageHeader}
                </span>
              </motion.h5>

              <motion.p
                initial={{ opacity: 0, rotate: -10 }}
                animate={{ opacity: 1, rotate: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="fw-bold">
                  {displayHeaderMessage.messageBody}
                </span>
              </motion.p>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center' }} className="bg-secondary">
          <div
            style={{ textAlign: 'center' }}
            className="container container-m"
          >
            <div style={{ textAlign: 'center' }} className="row ">
              <motion.div
                className={`
                  col-sm-6 col-md-6 col-lg-4 
                  d-flex 
                  justify-content-center 
                  align-items-center 
                  columnMain-button 
                  ${selectedCategory === 'Hosting' ? 'active' : ''}
                `}
                initial={{ opacity: 0, z: -1000, rotateX: -90 }}
                animate={{ opacity: 1, z: 0, rotateX: 0 }}
                transition={{ duration: 1 }}
                onClick={() => onSelectCategory('Hosting')}
                style={{
                  cursor: 'pointer'
                }}
              >
                {hostingIcon}
                <p
                  style={{ paddingLeft: '4px' }}
                  className="text-white mt-3 fw-bold "
                >
                  Hosting
                </p>
              </motion.div>
              <motion.div
                className={`
                col-sm-6 col-md-6 col-lg-4 
                d-flex 
                justify-content-center 
                align-items-center 
                columnMain-button ${selectedCategory === 'Rocketslip' ? 'active' : ''}`}
                initial={{ opacity: 0, z: -1000, rotateX: -90 }}
                animate={{ opacity: 1, z: 0, rotateX: 0 }}
                transition={{ duration: 1 }}
                onClick={() => onSelectCategory('Rocketslip')}
                style={{ cursor: 'pointer' }}
              >
                {rocketslipIcon}
                <p
                  style={{ paddingLeft: '4px' }}
                  className="text-white mt-3 fw-bold"
                >
                  Rocketslip
                </p>
              </motion.div>
              <motion.div
                className={`
                col-sm-6 col-md-6 col-lg-4 
                d-flex 
                justify-content-center 
                align-items-center 
                columnMain-button ${selectedCategory === 'X-One' ? 'active' : ''}`}
                initial={{ opacity: 0, z: -1000, rotateX: -90 }}
                animate={{ opacity: 1, z: 0, rotateX: 0 }}
                transition={{ duration: 1 }}
                onClick={() => onSelectCategory('X-One')}
                style={{ cursor: 'pointer' }}
              >
                {xoneIcon}
                <p
                  style={{ paddingLeft: '4px' }}
                  className="text-white mt-3 fw-bold"
                >
                  X-One
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
