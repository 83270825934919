import { WebsiteStatusConfig } from '../../../../utils/interfaces';
import { apiMessages, maintenanceMessages, websiteMessages } from '../messages';

export const getUpcomingMaintenance = (
  serviceStatus?: number | null
): WebsiteStatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < maintenanceMessages.length
  ) {
    return maintenanceMessages[serviceStatus];
  } else {
    return maintenanceMessages[3];
  }
};

export const getWebsiteStatus = (
  serviceStatus?: number | null
): WebsiteStatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < websiteMessages.length
  ) {
    return websiteMessages[serviceStatus];
  } else {
    return websiteMessages[3];
  }
};

export const getAPIStatus = (
  serviceStatus?: number | null
): WebsiteStatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < apiMessages.length
  ) {
    return apiMessages[serviceStatus];
  } else {
    return apiMessages[3];
  }
};
