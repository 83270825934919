import React from 'react';
import { createRoot } from 'react-dom/client';
import { HomePage } from './pages/home';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { GlobalStatusProvider } from './pages/contextProviders/contextProvider';
import { HostingStatusProvider } from './pages/contextProviders/contextProviderHosting';
import { RocketSlipStatusProvider } from './pages/contextProviders/contextProviderRocketslip';
import { XoneStatusProvider } from './pages/contextProviders/contextProviderXone';

// import './utils';
const container = document.getElementById('root');

if (container !== null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <GlobalStatusProvider>
        <RocketSlipStatusProvider>
          <HostingStatusProvider>
            <XoneStatusProvider>
              <HashRouter>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                </Routes>
              </HashRouter>
              <Toaster />
            </XoneStatusProvider>
          </HostingStatusProvider>
        </RocketSlipStatusProvider>
      </GlobalStatusProvider>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element');
}
