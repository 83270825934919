import '../../../../assets/css/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { WebsiteCard, getWebsiteStatus } from '../components';
import RocketslipStatusContext from '../../../contextProviders/contextProviderRocketslip';

interface WebsiteStatusProps {
  onStatusUpdate: (status: number) => void;
}

export const WebsiteStatus: React.FC<WebsiteStatusProps> = ({
  onStatusUpdate
}) => {
  const { websiteStatus } = useContext(RocketslipStatusContext);

  useEffect(() => {
    onStatusUpdate(websiteStatus);
  }, [websiteStatus, onStatusUpdate]);

  const serviceStatusConfig = getWebsiteStatus(websiteStatus);

  return (
    <div className="col-lg-12">
      <div className="row"></div>
      <div className="row">
        <div className="my-4 col-lg-12">
          <motion.div
            initial={{ opacity: 0, z: -1000, rotateX: -90 }}
            animate={{ opacity: 1, z: 0, rotateX: 0 }}
            transition={{ duration: 1 }}
          >
            <WebsiteCard
              title={serviceStatusConfig.title}
              text={serviceStatusConfig.text}
              className={serviceStatusConfig.className}
              status={websiteStatus}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
