import {
  StatusConfig,
  WebsiteStatusConfig
} from '../../../../utils/interfaces';
import {
  maintenanceMessages,
  webserverMessages,
  networkMessages
} from '../messages';

export const getUpcomingMaintenance = (
  serviceStatus?: number | null
): WebsiteStatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < maintenanceMessages.length
  ) {
    return maintenanceMessages[serviceStatus];
  } else {
    return maintenanceMessages[3];
  }
};

export const getNetworkStatusConfig = (
  serviceStatus?: number | null
): StatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < networkMessages.length
  ) {
    return networkMessages[serviceStatus];
  } else {
    return networkMessages[3];
  }
};

export const getWebServerStatus = (
  serviceStatus?: number | null
): WebsiteStatusConfig => {
  if (
    serviceStatus !== null &&
    typeof serviceStatus !== 'undefined' &&
    serviceStatus >= 0 &&
    serviceStatus < webserverMessages.length
  ) {
    return webserverMessages[serviceStatus];
  } else {
    return webserverMessages[3];
  }
};
