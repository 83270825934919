import { StatusConfig } from '../../../../utils/interfaces';

export const networkMessages: StatusConfig[] = [
  {
    title: 'Network Status',
    text: `
      <b>There are no problems to report at this moment.</b></br>
      All services are functioning optimally with no reported issues.
      `,
    className: 'nagios-success-status text-dark',
    status: 'success'
  },
  {
    title: 'Network Status',
    text: `
      <b>Title:</b> Some services are currently affected.<br>
      <b>Current Status:</b> The issue is currently being investigated, and corrective measures are being taken to resolve it promptly.<br>
      <ul>
        <li>Some customers may experience intermittent connectivity.</li>
      </ul>
      `,
    className: 'bg-warning',
    status: 'warning'
  },
  {
    title: 'Network Status',
    text: `
      <b>Title:</b> All services are currently affected.<br>
      <b>Current Status:</b> Investigation and remediation efforts are underway. Our network engineering team is actively working to diagnose and resolve the issue.<br>
      <ul>
        <li>Multiple services are currently affected.</li>
      </ul>
      `,
    className: 'bg-danger text-white',
    status: 'error'
  },
  {
    title: 'Network Status',
    text: 'Unable to determine network status.',
    status: 'unknown'
  }
];
